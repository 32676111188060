<template>
  <div class="card-body">
    <div v-if="excelReferencesList">
      <base-table
          v-if="shownData.length"
          :columns="excelReferenceFields"
          :rows="shownData"
      />

      <table-pagination
          v-if="shownData.length"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="table-preloader">
      <preloader />
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, ref, watch} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";

export default {
  name: "excel-references-table",
  components: {
    BaseTable,
    CustomSelect2,
    VPagination,
    Preloader,
    TablePagination,
  },
  setup(props, context) {
    const store = useStore(),
      route = useRoute();

    const countOnPage = ref(15),
      isPreloader = ref(false);
    const excelReferencesList = computed(
        () => store.state.references.excelReferencesList?.staffs
      ),
      rowData = computed(() => {
        return Object.entries(excelReferencesList.value).map(([staff, data]) => {
          return {
            email: staff,
            KPI: data.kpi ?? 0,
            status: data.status === 'success' ? 'успешно' : 'сотрудник с таким email не найден',
          };
        });
      });

    const excelReferenceFields = [
      {
        field: "email",
        headerName: "Email",
      },
      {
        field: "KPI",
        headerName: "Средний KPI",
      },
      {
        field: "excel_status",
        headerName: "Статус",
        successColor: "black",
      },
    ];

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

          if (data.some(el => el.status !== 'успешно')) {
            listenerDisabledBtnCalculate.value = true
            context.emit("disBtnCalc", listenerDisabledBtnCalculate);
          }

          data = sortData(data, 'status', 'down');
          data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const pageDataStart = ref(0);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const listenerDisabledBtnCalculate = ref(false)

    onBeforeUnmount(() => {
      store.commit("references/setExcelReferencesList", null);
    });

    return {
      route,
      countOnPage,
      isPreloader,
      excelReferencesList,
      rowData,
      changePage,
      excelReferenceFields,
      shownData,

    };
  },
};
</script>

<style lang="scss" scoped>
.head-sort {
  .form-group {
    min-width: 50%;
  }
}
</style>
