<template>
  <div v-if="!isPreloader" class="table-responsive relations-table">
    <div class="view-container">
      <div class="card staff-table">
        <div class="card-header card-header-divider">
          <div class="card-title">Полученный файл</div>
          <div v-if="excelReferencesList" class="tools">
            <button class="btn btn-accent" :class="{ disabled: listenerDisabledBtnCalculate }" @click="calculate">Рассчитать</button>
          </div>
        </div>
        <excel-references-table @disBtnCalc="disBtnCalc"/>
      </div>
    </div>
  </div>
  <div v-else-if="isPreloader" class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import ExcelReferencesTable from "../../feature/references/tables/ExcelReferencesTable.vue";
import Preloader from "../../components/Technical/Preloader.vue";
import useErrors from "../../mixins/useErrors";
import standartError from "../../mixins/standartError";
import { ref } from "@vue/reactivity";
import router from "@/router";
export default {
  components: { ExcelReferencesTable, Preloader },
  name: "excel-references-page",
  setup() {
    const store = useStore(),
    { setErrors, clearCustomErrors } = useErrors();
    const isPreloader = ref(false);
    const listenerDisabledBtnCalculate = ref(false)
    const excelReferencesList = computed(
      () => {
        if (store.state.references.excelReferencesList == null) {
          router.push("/references/files");
        }

        return store.state.references.excelReferencesList;
      }
    );

    const calculate = () => {
      isPreloader.value = true;
      store.dispatch("analytics/calculateProfile", {id: excelReferencesList.value.id})
        .then(() => {
          isPreloader.value = false
          router.push("/references/profiles");
        })
        .catch(standartError(setErrors, clearCustomErrors, isPreloader));
    };

    const disBtnCalc = (data) => {
      listenerDisabledBtnCalculate.value = data.value
    }

    return {
      calculate,
      isPreloader,
      excelReferencesList,
      disBtnCalc,
      listenerDisabledBtnCalculate
    };
  },
};
</script>

<style>

button.disabled {
  cursor: not-allowed;
  background-color: #7fd3b0;
  border-color: #7fd3b0;
}
</style>